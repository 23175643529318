import React, { forwardRef, useState, useCallback } from "react";
import { asUploadButton } from "@rpldy/upload-button";
import { Typography, Space } from "antd";
import { useBatchAddListener, useBatchFinishListener } from "@rpldy/uploady";
import UploadDropZone from "@rpldy/upload-drop-zone";
import { InboxOutlined } from "@ant-design/icons";
import { FaFileSignature, FaFileVideo, FaFileUpload } from "react-icons/fa";

const { Title, Paragraph } = Typography;

export const CustomUploadyBox = asUploadButton(
  forwardRef(({ ...props }, ref) => {
    const [text, setText] = useState("Select file");

    useBatchAddListener((batch) => {
      setText(batch.items[0].file.name);
    });

    useBatchFinishListener(() => {
      setText("Select file");
    });

    return (
      <div {...props} ref={ref} id="form-upload-button">
        <UploadDropZone
          onDragOverClassName="drag-over"
          grouped={false}
          maxGroupSize={1}
        >
          <div
            className="ant-upload ant-upload-drag"
            style={{ overflowWrap: "break-word" }}
          >
            <span
            /*               tabIndex={0}
              className="ant-upload ant-upload-btn"
              role="button" */
            >
              <div style={{ margin: 25, textAlign: "center" }}>
                <Space wrap className="ant-upload-drag-icon">
                  <FaFileSignature size={"5.5em"} />+
                  <FaFileVideo size={"5em"} />=
                  <FaFileUpload size={"5em"} />
                  {/*  <InboxOutlined /> */}
                </Space>
                <p className="ant-upload-text">
                  <strong>
                    Klicke oder ziehe ein Bild mit deiner Unterschrift{" "}
                    <strong style={{ color: "red" }}>(png, jpeg)</strong> und
                    deine Videodatei{" "}
                    <strong style={{ color: "red" }}>
                      (mov, mp4, 3gp, wmv)
                    </strong>{" "}
                    auf das Feld um sie hochzuladen.
                  </strong>
                </p>
                <p className="ant-upload-hint">
                  Du kannst{" "}
                  <strong>
                    beide Dateien gleichzeitig auswählen oder in das Feld ziehen
                  </strong>{" "}
                  aber <strong>genau ein Bild mit deiner Unterschrift </strong>{" "}
                  <strong style={{ color: "red" }}>(max. 500KB) </strong>
                  <strong>und eine Videodatei</strong>{" "}
                  <strong style={{ color: "red" }}>(max. 5GB)</strong>{" "}
                  hochladen. Bitte sei dir sicher, dass du die Urheberrechte
                  über die Dateien besitzt.
                </p>
              </div>
            </span>
          </div>
        </UploadDropZone>
      </div>
    );
  })
);
