import moment from "moment";
import "moment/locale/de-at";

const dateFormat = "DD.MM.YYYY";

export const validateMessagesDE = {
  required: "Wird benötigt!",
  types: {
    email: "Ist keine gültige E-Mail Adresse!",
    number: "Ist keine Nummer!",
    innerText: "Wird benötigt!",
  },
  number: {
    range: "${label} muss zwischen ${min} und ${max} sein",
  },
  errorMessageText: "Wird benötigt!",
  errorMessageObject: "Wird benötigt!",
  errorMessageNoUpload:
    "Sieht so aus als hättest du deine Produktion noch nicht hochgeladen! Bitte check das nochmal ab.",
  errorOnMetaDataUpload:
    "Es gab ein Problem beim Versenden der Daten. Bitte versuch es nocheinmal oder wende dich an die Administatoren: ",
  errorInputStringExceedsCharLimit: "Eingabe ist zu lange",
  ageRestriction: "Alter zwischen 6 und 22!",
};

export const generalTextsDE = {
  currentDate: moment(new Date()).format(dateFormat),
  pdfHeadline1: "EINREICHBOGEN VIDEO UND FILMTAGE",
  applicationPhaseInitMessage: "Intialisiere ...",
  applicationPhaseDisabledMessage:
    "Zur Zeit finden keine Einreichungen statt. Die nächste Einreichphase startet diesen April.",
  uploadSuccessMessage:
    "Liebe Filmemacherin, lieber Filmemacher, Deine Daten wurden erfolgreich übermittelt. Wir freuen uns sehr über deine Einreichung! In Kürze senden wir dir ein Bestätigungs E-Mail zu, in dem du erfährst, wie es mit deiner Einreichung weitergeht.",
  privacyLink: "https://www.wienxtra.at/datenschutz/",
  mainTitle: "VIDEO & FILMTAGE",
  mainSubTitle1: "DAS KURZFILMFESTIVAL FÜR JUNGE FILMEMACHER_INNEN",
  mainSubTitle2: "BIS 22 JAHRE",
  mainSubTitle3: "",
  mainCookieInfoText1: "",
  mainCookieInfoText2: "",
  mainLongDescription: "",
  titleSubSectionConditions: "Datenschutzbestimmungen",
  save: "Speichern",
  otherParticipants: "",

  titleSection1: "TITEL",
  titleSection2: "FILMEMACHER_INNEN",
  titleSection3: "KONTAKTPERSON",
  titleSection4: "DETAILS ZUR PRODUKTION",
  titleSection5: "NEWSLETTER",
  newLetterAgreementLabel: "Bestätigung: ",
  titleSection6: "TEILNAHMEBEDINGUNGEN",
  conditionsOfParticipationLabel: "Bestätigung: ",
  conditionsOfParticipation: `Teilnahmebedingungen: Dein Film ist nicht länger als 30 Minuten und nicht älter als 1 Jahr. Du warst zum Zeitpunkt der Produktion nicth älter als 22 Jahre. Wir können deinen Film nur zeigen, wenn Du beim Sceening während des Festivals anwesend sein kannst. Wir können leider keine Reisekosten übernehmen`,

  participationConditionsAgreementPart1:
    "Ich/Wir erkenne/n die Teilnahmebedingungen an. Die im Film abgebildeteten Personen sind mit einer Vorführung im Zusammenhang mit den video&filmtagen einverstanden. Dem WIENXTRA-Medienzentrum erteile/n ich/wir die Genehmigung, Stills aus meiner/unserer Produktion für die Bewerbung auf der Festival-Homepage, im Programmheft sowie in der Presse zu verwenden,das Video für Archivzwecke zu kopieren oder für nichtgewerbliche Vorführungen und Ausstrahlungen (OKTO, Web-TV) zu nutzen und meinen/unseren Namen an Interessenten (TV-Anstalten, Wettbewerbe, Festivals usw.) weiterzugeben. Es besteht kein rechtlicher Anspruch auf Aufnahme in das Programm. Weiters habe ich die",
  participationConditionsAgreementPart2: "Datenschutzerklärung",
  participationConditionsAgreementPart3: "zur Kenntnis genommen.",

  newsLetterAgreement:
    "Mit der Bestätigung der WIENXTRA-AGB stimme(n) ich/wir bis auf Widerruf der Verwendung meiner/unserer bekannt gegebenen Daten zum Zweck der Zusendung von Informationen über Angebote des Vereins WIENXTRA zu.",

  titleSection7: "ANWESENHEIT BEIM FESTIVAL",
  presenceAgreement:
    "Ich bestätige hiermit, dass ich bzw. jemand aus dem Filmteam meinen/unseren Film während des Festivals persönlich in Wien vorstellen kann. Die Video & Filmtage 2025 finden vom 2. bis 5. Oktober 2025 statt.",

  verificationHeadline: "VERIFIZIEREN",
  send: "Abschicken",
  upload: "HOCHLADEN",
  uploaded:
    "Du hast deine Dateien erfolgreich hochgeladen. Kontrolliere ob alle unbedingt benötigten Formulareinträge vorhanden sind und dann drücke auf Abschicken",
  yes: "Ja",
  no: "Nein",

  productionTitleLabel: "Titel der Produktion",
  productionTitlePlaceholder: "Welchen Namen hat deine/eure Produktion?",
  firstNameLabel: "Vorname",
  firstNamePlaceholder: "Vorname",
  lastNameLabel: "Nachname",
  lastNamePlaceholder: "Nachname",
  birthdateLabel: "Alter",
  nameMainContactLabel: "Name",
  nameMainContactPlaceholder: "Vorname des Hauptkontakts",
  lastNameMainContactPlaceholder: "Nachname des Hauptkontakts",
  addressMainContactLabel: "Adresse",
  addressMainContactStreetAndNumberPlaceholder: "Straße, Hausnummer",
  addressMainContactPostcodePlaceholder: "PLZ",
  addressMainContactPlacePlaceholder: "Ort",
  addressMainContactCountryPlaceholder: "Land",
  phoneMainContactLabel: "Telefon",
  phoneMainContactPlaceholder: "Nummer eingeben.",
  emailMainContactLabel: "E-Mail",
  emailMainContactPlaceholder: "email@hauptkontakt.at",
  productionLengthLabel: "Filmlänge",
  productionLengthPlaceholder: "Wie lang ist der Film / das Video?",
  productionTimeLabel: "Zeitraum der Produktion",
  productionTimePlaceholder: "Wie lange wurde daran gearbeitet?",
  productionShortSummaryLabel: "Kurze Inhaltsangabe",
  productionShortSummaryPlaceholder:
    "Ein Beschreibung des Inhalts für das Programmheft. (max. 200 Zeichen)",
  productionParticipantsDescriptionLabel: "Zu deiner/eurer Person",
  productionParticipantsDescriptionPlaceholder:
    "Wer bist du/seid ihr, wie habt ihr euch gefunden? (max. 200 Zeichen)",
  hasFurtherProjectsPlannedLabel: "Weitere Projekte: ",
  hasFurtherProjectsPlannedQuestion: "Planst du/plant ihr weitere Projekte?",

  furtherProjectsDescriptionLabel: "Weitere Projekte: ",
  furtherProjectsDescriptionPlaceholder:
    "Welche(s) ist/sind das? Beschreibung des Projekts / der Projekte...",
  hasReceivedHelpLabel: "Hilfe: ",
  helpReceivedQuestion: "Hat dir/euch jemand bei der Umsetzung geholfen?",
  productionParticipantHelpersLabel: "Beschreibung:",
  productionParticipantHelpersPlaceholder: "Wer und in welcher Form?",
  hasPublicContactInformationLabel: "Öffentlicher Kontakt: ",
  hasPublicContactInformationQuestion:
    "Wollt ihr Kontaktdaten für das Programmheft angeben?",
  emailPublicLabel: "E-Mail (Öffentlich)",
  emailPublicPlaceholder:
    "Öffentliche E-Mail Adresse für das Programmheft: ich@meinedomain.at",
  websitePublicLabel: "Website (Öffentlich)",
  websitePublicPlaceholder:
    "Öffentliche Web Adresse für das Programmheft: meinedomain.at",
   infoContact1: "Solltest du Probleme bei der Einreichung haben melde dich bitte bei uns:",
   infoContact2: "videoundfilmtage@wienxtra.at",
   infoContact3: "oder",
   infoContact4: "+43 1 909 4000 83444",
   infoContactNormalizedPhone: "+431909400083444"
};
