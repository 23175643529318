import Log from "../../../src/Log";
import React, { useState, useEffect, useRef } from "react";

import {
  Form,
  Button,
  Checkbox,
  Input,
  Typography,
  Space,
  Select,
  InputNumber,
  Switch,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import PDFKitIntegration from "../pdfkitpage/PDFKitIntegration";
import { CustomUploadyProgress } from "../../components/common/custom/CustomUploadyProgress";
import { CustomUploadyBox } from "../../components/common/custom/CustomUploadyBox";
import "react-image-crop/dist/ReactCrop.css";
import BackEndService from "../../services/BackEndService";

import { withTheme } from "styled-components";

import {
  useItemFinishListener,
  useBatchAbortListener,
  useItemProgressListener,
} from "@rpldy/uploady";
import { useAbortAll } from "@rpldy/uploady";

import "moment/locale/de-at";
import { DatePicker } from "antd";

import FrontEndService from "../../services/FronEndService";
import countries from "../../assets/data/countries.json";
import countryDialCodes from "../../assets/data/countryDialCodes.json";
import Colors from "../../components/common/theme/colors";

import {
  validateMessagesDE,
  generalTextsDE,
} from "../../assets/localizedStrings";

import makePDF from "../../pages/pdfkitpage/makePDF";
import "../../pages/pdfkitpage/register-files";
import createUploader, { UPLOADER_EVENTS } from "@rpldy/uploader";
import UrlService from "../../services/UrlService";
import blobStream from "blob-stream";

const PDFDocument = require("pdfkit").default;

const primaryColor = Colors.primary;

const { TextArea } = Input;

const formItemLayout = {
  key: 1,
  labelCol: {
    key: 11,
    xs: {
      key: 111,
      span: 24,
    },
    sm: {
      key: 112,
      span: 6,
    },
  },
  wrappercol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const tailFormItemLayout1 = {
  key: 2,
  wrappercol: {
    key: 21,
    xs: {
      key: 211,
      span: 24,
      offset: 0,
    },
    sm: {
      key: 212,
      span: 24,
      offset: 2,
    },
  },
};

const tailFormItemLayout2 = {
  key: 3,
  wrappercol: {
    key: 31,
    xs: {
      key: 311,
      span: 24,
      offset: 0,
    },
    sm: {
      key: 312,
      span: 24,
      offset: 2,
    },
  },
};

const tailFormItemLayout3 = {
  key: 4,
  wrappercol: {
    key: 41,
    xs: {
      key: 411,
      span: 24,
      offset: 0,
    },
    sm: {
      key: 412,
      span: 24,
      offset: 2,
    },
  },
};

const { Title, Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;

const dateFormat = "YYYY/MM/DD";
const monthFormat = "YYYY/MM";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

const defaultValue = makePDF
  .toString()
  .split("\n")
  .slice(1, -1)
  .join("\n")
  .replace(/^ {2}/gm, "");

const ParticipantRegistrationForm = () => {
  useEffect(() => {
    getApplicationPhase();
  }, []);

  useEffect(() => {
    checkHasUploadState();
  });

  const iframe = useRef();

  const abortAll = useAbortAll();

  const uploader = createUploader({
    destination: { url: UrlService.uploadUrl() },
    autoUpload: true,
  });

  uploader.on(UPLOADER_EVENTS.ITEM_START, (item) => {
    Log.d(`item ${item.id} started uploading`);
  });

  uploader.on(UPLOADER_EVENTS.ITEM_FINISH, (item) => {
    Log.d(`item ${item.id} finished uploading`);
    let uploadPDFMetaData =
      item.uploadResponse.data; /* [item.uploadResponse.length - 1].data; */
    Log.d(uploadPDFMetaData);

    if (uploadPDFMetaData) {
      allFormValues["uploadPDFMetaData"] = uploadPDFMetaData;
      processPDFCallback(allFormValues);
    } else {
      alert(validateMessages.errorOnMetaDataUpload);
    }
  });

  const processPDFCallback = async (allFormValues: any) => {
    Log.d(
      "Uploaded PDF. Now updating and sending Metadata. Then sending Email."
    );

    allFormValues["uploadImageMetaData"] = uploadImageMetaData;
    allFormValues["uploadVideoMetaData"] = uploadVideoMetaData;
    setAllFormValues(allFormValues);

    const response = await FrontEndService.uploadMetaData(allFormValues);

    if (response.success) {
      Log.d("Info: MetaDataService.doUpload " + JSON.stringify(response.data));

      const responseSendMail = await FrontEndService.sendMail(allFormValues);

      if (responseSendMail.success) {
        Log.d(
          "Info: MetaDataService.sendMail " +
            JSON.stringify(responseSendMail.data)
        );
        setAllFormValues(allFormValues);
        setIsFormClosed(true);
      } else {
        alert(
          validateMessages.errorOnMetaDataUpload +
            JSON.stringify(responseSendMail.data)
        );
      }
    } else {
      alert(
        validateMessages.errorOnMetaDataUpload + JSON.stringify(response.data)
      );
    }
  };

  const processPDF = (
    texts: any,
    formValues: any,
    shouldUpload: any,
    showPreview: any
  ) => {
    try {
      // eslint-disable-next-line no-new-func
      const createdPDFRef = new makePDF(
        PDFDocument,
        blobStream,
        iframe.current,
        texts,
        uploader,
        formValues,
        shouldUpload,
        showPreview
      );
    } catch (e) {
      Log.e(e);
    }
  };

  const [debugMode, setDebugMode] = useState(false);

  const validateMessages = validateMessagesDE;
  const generalTexts = generalTextsDE;
  const [isFormClosed, setIsFormClosed] = useState(false);
  const [shouldShowPreview, setShouldShowPreview] = useState(false);
  const [
    isRenderAndUploadPDFEnabled,
    setIsRenderAndUploadPDFEnabled,
  ] = useState(true);
  const [showPDFEditPreview, setShowPDFEditPreview] = useState(false);

  const [checkedNewLetterAgreement, setCheckedNewLetterAgreement] = useState(
    debugMode
  );
  const [
    checkedConditionsOfParticipation,
    setCheckedConditionsOfParticipation,
  ] = useState(debugMode);

  const [
    checkedPresenceAgreement,
    setCheckedPresenceAgreement,
  ] = useState(debugMode);

  const [form] = Form.useForm();

  const [allFormValues, setAllFormValues]: any = useState(
    debugMode
      ? {
          productionTitle: "Die unwahrscheinliche komplexität des Seins.",
          participantsCollection: [
            { firstName: "Jim", lastName: "Jarmush", birthdate: 12 },
            { firstName: "Ethan", lastName: "Cohen", birthdate: 15 },
            { firstName: "Sofia", lastName: "Coppola", birthdate: 12 },
            { firstName: "Marvin", lastName: "Kren", birthdate: 20 },
          ],
          firstNameMainContact: "Oliver",
          lastNameMainContact: "Rudoll",
          nameMainContact:
            '{"firstNameMainContact":"Oliver","lastNameMainContact":"Rudoll"}',
          addressMainContactStreetAndNumber: "Blaugsichtgasse 44 / 3 / 2",
          addressMainContactPostcode: "1010",
          addressMainContactPlace: "Wien",
          addressMainContactCountry: "Österreich",
          addressMainContact:
            '{"addressMainContactStreetAndNumber":"Blaugsichtgasse 44 / 3 / 2","addressMainContactPostcode":"1010","addressMainContactPlace":"Wien","addressMainContactCountry":"Österreich"}',
          prefix: "+43",
          phoneMainContact: "555889977",
          emailMainContact: "oliver.rudoll@wienxtra.at",
          productionLength: "2 Wochen",
          productionTime: "ca. 3 Monate",
          productionShortSummary:
            'The final story, "Lost in Space", introduces Johnny (Joe Strummer). Upset after losing his job and his girlfriend (Dee Dee), Johnny – called Elvis, much to his chagrin.',
          productionParticipantsDescription:
            "Die Zusammenarbeit von Jim Jarmusch und Neil Young führte 1995 nicht nur zum betörenden Soundtrack von Dead Man, sondern zwei Jahre später auch zu einem aussergewöhnlichen Musikfilm.",
          newLetterAgreement: true,
          conditionsOfParticipation: true,
          uploadImageMetaData: {
            path: "upload/productions/",
            name: "aaaTest.png",
            mime_type: "image-png",
          },
          uploadVideoMetaData: {
            path: "upload/productions/",
            name: "aaaTest.mov",
            mime_type: "video-quicktime",
          },
        }
      : null
  );

  const [uploadImageMetaData, setUploadImageMetaData] = useState(
    debugMode
      ? {
          path: "upload/productions/",
          name: "aaaTest.png",
          mime_type: "image-png",
        }
      : null
  );
  const [uploadVideoMetaData, setUploadVideoMetaData] = useState(
    debugMode
      ? {
          path: "upload/productions/",
          name: "aaaTest.mov",
          mime_type: "video-quicktime",
        }
      : null
  );

  useBatchAbortListener((value) => {
    Log.d(`items ${JSON.stringify(value)} aborted`);
    setUploadImageMetaData(null);
    setUploadVideoMetaData(null);

    if (allFormValues) {
      allFormValues["uploadImageMetaData"] = "";
      allFormValues["uploadVideoMetaData"] = "";
    }
  });

  useItemFinishListener((item) => {
    Log.d(`item ${item.id} finished`);

    let response = item.uploadResponse[item.uploadResponse.length - 1].data;

    if (
      response.mime_type === "image-png" ||
      response.mime_type === "image-jpeg"
    ) {
      setUploadImageMetaData(response);
      Log.d(`Response Image Upload:` + JSON.stringify(response));
    } else if (
      response.mime_type === "video-mpeg" ||
      response.mime_type === "video-quicktime" ||
      response.mime_type === "video-mp4" ||
      response.mime_type === "video-3gpp" ||
      response.mime_type === "video-3gpp2" ||
      response.mime_type === "video-x-ms-asf" ||
      response.mime_type === "application-octet-stream"
    ) {
      setUploadVideoMetaData(response);
      Log.d(`Response Video Upload:` + JSON.stringify(response));
    } else {
      Log.d(`Response Upload:` + JSON.stringify(response));
    }
  });

  const [hasFurtherProjects, setHasFurtherProjects] = useState(false);
  const [
    hasPublicContactInformation,
    setHasPublicContactInformation,
  ] = useState(false);
  const [hasReceivedHelp, setHasReceivedHelp] = useState(false);

  const [participantsCollection, setCollection] = useState([
    { id: 0 },
    { id: 1 },
  ]);

  useEffect(() => {
    setHasFurtherProjects(false);
  }, [form.getFieldValue("hasFurtherProjectsPlanned")]);

  useEffect(() => {
    if (debugMode) {
      form.setFieldsValue({
        productionTitle: "Die unwahrscheinliche komplexität des Seins.",
        participantsCollection: [
          { firstName: "Jim", lastName: "Jarmush", birthdate: 12 },
          { firstName: "Ethan", lastName: "Cohen", birthdate: 15 },
          { firstName: "Sofia", lastName: "Coppola", birthdate: 12 },
          { firstName: "Marvin", lastName: "Kren", birthdate: 20 },
        ],
        firstNameMainContact: "Oliver",
        lastNameMainContact: "Rudoll",
        nameMainContact:
          '{"firstNameMainContact":"Oliver","lastNameMainContact":"Rudoll"}',
        addressMainContactStreetAndNumber: "Blaugsichtgasse 44 / 3 / 2",
        addressMainContactPostcode: "1010",
        addressMainContactPlace: "Wien",
        addressMainContactCountry: "Österreich",
        addressMainContact:
          '{"addressMainContactStreetAndNumber":"Blaugsichtgasse 44 / 3 / 2","addressMainContactPostcode":"1010","addressMainContactPlace":"Wien","addressMainContactCountry":"Österreich"}',
        prefix: "+43",
        phoneMainContact: "555889977",
        emailMainContact: "oliver.rudoll@wienxtra.at",
        productionLength: "2 Wochen",
        productionTime: "ca. 3 Monate",
        productionParticipantsDescription:
          "Die Zusammenarbeit von Jim Jarmusch und Neil Young führte 1995 nicht nur zum betörenden Soundtrack von Dead Man, sondern zwei Jahre später auch zu einem aussergewöhnlichen Musikfilm.",
        productionShortSummary: `The final story, "Lost in Space", introduces Johnny (Joe Strummer). Upset after losing his job and his girlfriend (Dee Dee), Johnny – called Elvis, much to his chagrin.`,
        newLetterAgreement: true,
        conditionsOfParticipation: true,
      });
    }
  });

  const onVerifyCaptcha = (token) => {
    //setValue('captchaToken', token);
    Log.d(token);
  };

  const onFinish = async (values: any) => {
    if (uploadImageMetaData !== null && uploadVideoMetaData !== null) {
      values["uploadImageMetaData"] = uploadImageMetaData;
      values["uploadVideoMetaData"] = uploadVideoMetaData;
      Log.d("Values? " + JSON.stringify(values));
      setAllFormValues(values);
      processPDF(
        generalTexts,
        values,
        isRenderAndUploadPDFEnabled,
        shouldShowPreview
      );
    } else {
      alert(validateMessages.errorMessageNoUpload);
    }
  };

  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [phonePreDial, setPhonePreDial] = useState("+43");

  const onChangeCountrySelect = (value) => {
    let countryCode = countries.find(
      (countryEntry) => countryEntry.name === value
    ).alpha2;
    Log.d(`selected ${value} with countryCode: ${countryCode}`);
    setSelectedCountryCode(countryCode);
    let countryDialCode = countryDialCodes.find(
      (countryEntry) =>
        countryEntry.code.toLowerCase() === countryCode.toLowerCase()
    ).dial_code;
    setPhonePreDial(countryDialCode);
    handleParentAddressMainContactValue(value);
  };

  const onSearchCountrySelect = (val) => {
    Log.d("search: " + val);
  };

  const prefixTel = (
    <Form.Item initialValue={phonePreDial} name="prefix" noStyle>
      {phonePreDial}
    </Form.Item>
  );

  const textInputLengthValidation = (rule: any, value: any, callback: any) => {
    if (value === undefined) {
      return Promise.resolve();
    }

    if (value.length > 200) {
      return Promise.reject(validateMessages.errorInputStringExceedsCharLimit);
    } else {
      return Promise.resolve();
    }
  };

  const textInputLengthValidationShort = (
    rule: any,
    value: any,
    callback: any
  ) => {
    if (value === undefined) {
      return Promise.resolve();
    }

    if (value.length > 40) {
      return Promise.reject(validateMessages.errorInputStringExceedsCharLimit);
    } else {
      return Promise.resolve();
    }
  };

  const textInputPostcodeValidation = (
    rule: any,
    value: any,
    callback: any
  ) => {
    if (value === undefined) {
      return Promise.resolve();
    }

    if (value.length > 10) {
      return Promise.reject(validateMessages.errorInputStringExceedsCharLimit);
    } else {
      //to fix deprecation warning
      return Promise.resolve();
    }
  };

  const [nameMainContactState, setNameMainContactState] = useState({
    firstNameMainContact: "",
    lastNameMainContact: "",
  });

  const handleParentNameMainContactValue = (e: any) => {
    if (e.target.id === "control-hooks_firstNameMainContact") {
      nameMainContactState.firstNameMainContact = e.target.value;
      setNameMainContactState(nameMainContactState);
      form.setFieldsValue({ nameMainContact: nameMainContactState });
    } else if (e.target.id === "control-hooks_lastNameMainContact") {
      nameMainContactState.lastNameMainContact = e.target.value;
      setNameMainContactState(nameMainContactState);
      form.setFieldsValue({ nameMainContact: nameMainContactState });
    }
  };

  const [
    addressMainContactState,
    setAddressMainContactState,
  ] = useState(`{"addressMainContactStreetAndNumber": "",
                "addressMainContactPostcode": "",
                "addressMainContactPlace": "",
                "addressMainContactCountry": ""
            }`);

  const handleParentAddressMainContactValue = (e: any) => {
    if (
      e.target === undefined &&
      countries.find((countryEntry) => countryEntry.name === e)
    ) {
      let json = JSON.parse(addressMainContactState);
      json.addressMainContactCountry = e;
      let jsonString = JSON.stringify(json);
      setAddressMainContactState(jsonString);
      form.setFieldsValue({ addressMainContact: jsonString });
    } else {
      if (e.target.id === "control-hooks_addressMainContactStreetAndNumber") {
        let json = JSON.parse(addressMainContactState);
        json.addressMainContactStreetAndNumber = e.target.value;
        let jsonString = JSON.stringify(json);
        setAddressMainContactState(jsonString);
        form.setFieldsValue({ addressMainContact: jsonString });
      } else if (e.target.id === "control-hooks_addressMainContactPostcode") {
        let json = JSON.parse(addressMainContactState);
        json.addressMainContactPostcode = e.target.value;
        let jsonString = JSON.stringify(json);
        setAddressMainContactState(jsonString);
        form.setFieldsValue({ addressMainContact: jsonString });
      } else if (e.target.id === "control-hooks_addressMainContactPlace") {
        let json = JSON.parse(addressMainContactState);
        json.addressMainContactPlace = e.target.value;
        let jsonString = JSON.stringify(json);
        setAddressMainContactState(jsonString);
        form.setFieldsValue({ addressMainContact: jsonString });
      }
    }
  };

  class Age {
    public value: number;
    public validateStatus: string;
    public errorMsg: string;

    constructor(value: number, validateStatus: string, errorMsg: string) {
      this.value = value;
      this.validateStatus = validateStatus;
      this.errorMsg = errorMsg;
    }
  }

  let ageContainer = new Age(12, "", "");

  function validateNumber(number: Age): Age {
    if (number.value >= 6 && number.value <= 22) {
      number.validateStatus = "success";
      number.errorMsg = null;

      return number;
    } else {
      number.validateStatus = "error";
      number.errorMsg = validateMessages.ageRestriction;

      return number;
    }
  }

  const [ageValue, setNumber] = useState({
    value: 11,
    validateStatus: "",
    errorMsg: "",
  } as Age);

  const onNumberChange = (value) => {
    ageContainer.value = value;
    let validatedAgeContainer = validateNumber(ageContainer);
    setNumber(validatedAgeContainer);
  };

  const timePickerBlur = (time) => {
    form.setFieldsValue({
      productionLength: time,
    });
  };

  const onFormValuesChange = (changedValues, values) => {
    setAllFormValues(values);
  };

  const [applicationPhaseEnabled, setApplicationPhaseEnabled] = useState(false);
  const [applicationPhaseMessage, setApplicationPhaseMessage] = useState(
    generalTexts.applicationPhaseInitMessage
  );
  const getApplicationPhase = () => {
    BackEndService.getApplicationPhase().then((response) => {
      Log.d(" getApplicationPhase: " + JSON.stringify(response));
      if (response.data) {
        Log.d(JSON.stringify(response.data[0]));
        if (response.data[0].applicationPhase == 1) {
          setApplicationPhaseEnabled(true);
          setApplicationPhaseMessage("");
        } else {
          setApplicationPhaseEnabled(false);
          setApplicationPhaseMessage(
            generalTexts.applicationPhaseDisabledMessage
          );
        }
      }
    });
  };

  class UploadItem {
    public id: any;
    public completed: number;
    public state: string;

    constructor(id: any, completed: number, state: string) {
      this.id = id;
      this.completed = completed;
      this.state = state;
    }
  }

  let itemsInProgressIds: any[] = [];
  let itemsInProgress: UploadItem[] = [];

  const addItem = (item: any) => {
    if (item == null) {
      return;
    }
    if (!itemsInProgressIds.includes(item.id)) {
      Log.d(`Adding item ${item.id} State: ${item.state}`);
      itemsInProgressIds.push(item.id);
      itemsInProgress.push(new UploadItem(item.id, item.completed, item.state));
    }
  };

  addItem(
    useItemProgressListener((item) => {
      /*  Log.d(`item ${item.id} State: ${item.state}`); */
    })
  );

  const [uploadProgressStartedItem1, setUploadProgressStartedItem1] = useState(
    false
  );
  const [uploadProgressStartedItem2, setUploadProgressStartedItem2] = useState(
    false
  );

  const [
    uploadProgressFinishedItem1,
    setUploadProgressFinishedItem1,
  ] = useState(false);
  const [
    uploadProgressFinishedItem2,
    setUploadProgressFinishedItem2,
  ] = useState(false);

  const checkHasUploadState = () => {
    if (
      itemsInProgress[0] != null &&
      (itemsInProgress[0].state == "uploading" ||
        itemsInProgress[0].state == "finished")
    ) {
      setUploadProgressStartedItem1(true);
    } else if (
      itemsInProgress[1] != null &&
      (itemsInProgress[1].state == "uploading" ||
        itemsInProgress[1].state == "finished")
    ) {
      setUploadProgressStartedItem2(true);
    }

    if (
      itemsInProgress[0] != null &&
      itemsInProgress[0].id === "batch-1.item-1" &&
      itemsInProgress[0].completed == 100
    ) {
      setUploadProgressFinishedItem1(true);
    } else if (
      itemsInProgress[1] != null &&
      itemsInProgress[1].id === "batch-1.item-2" &&
      itemsInProgress[1].completed == 100
    ) {
      setUploadProgressFinishedItem2(true);
    }
  };

  return (
    <>
      {!applicationPhaseEnabled ? (
        <>
          <div className="form-layout-background">
            <Title level={3}>{applicationPhaseMessage}</Title>
          </div>
        </>
      ) : (
        <>
          {isFormClosed ? (
            <>
              <div className="form-layout-background">
                <Title level={3}>{generalTexts.uploadSuccessMessage}</Title>
              </div>
            </>
          ) : (
            <>
              <Form
                {...formItemLayout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                onValuesChange={onFormValuesChange}
                validateMessages={validateMessages}
                className="form-layout-background"
              >
                {/*               <Checkbox onChange={toggleDebugMode}>DEBUG MODE</Checkbox> */}

                <Title
                  level={1}
                  style={{
                    color: primaryColor,
                    marginBottom: 0,
                    textAlign: "center",
                  }}
                >
                  {generalTexts.mainTitle}
                </Title>
                <Title
                  level={4}
                  style={{
                    color: primaryColor,
                    marginBottom: 0,
                    textAlign: "center",
                  }}
                >
                  {generalTexts.mainSubTitle1}
                </Title>

                <Title
                  level={4}
                  style={{
                    color: primaryColor,
                    marginTop: 0,
                    marginBottom: 40,
                    textAlign: "center",
                  }}
                >
                  {generalTexts.mainSubTitle2}
                </Title>

                <Paragraph style={{ color: primaryColor, marginBottom: 60 }}>
                  {generalTexts.conditionsOfParticipation}
                </Paragraph>

                <Title level={3} {...tailFormItemLayout1}>
                  {generalTexts.titleSection1}
                </Title>

                <Form.Item
                  name="productionTitle"
                  label={generalTexts.productionTitleLabel}
                  rules={[
                    { required: true },
                    { validator: textInputLengthValidation },
                  ]}
                  className="wrap-label"
                >
                  <Input
                    placeholder={generalTexts.productionTitlePlaceholder}
                  />
                </Form.Item>

                <Title level={3}>{generalTexts.titleSection2}</Title>

                <Form.Item
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  <Form.List name="participantsCollection">
                    {(tempCollection, { add, remove }) => {
                      if (tempCollection.length <= 0) {
                        add();
                        setCollection(null);
                      }

                      return (
                        <div>
                          {tempCollection.map((field) => (
                            <Form.Item
                              key={field.key}
                              style={{
                                marginBottom: "0px",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Form.Item
                                label={generalTexts.firstNameLabel}
                                name={[field.name, "firstName"]}
                                fieldKey={[field.fieldKey, "firstName"]}
                                rules={[
                                  {
                                    required: true,
                                    message: validateMessages.required,
                                  },
                                ]}
                                help={""}
                                style={{
                                  display: "inline-block",
                                  width: "calc(35%)",
                                  paddingRight: "10px",
                                  marginBottom: "0px",
                                }}
                              >
                                <Input
                                  placeholder={
                                    generalTexts.firstNamePlaceholder
                                  }
                                  style={{
                                    marginBottom: "0px",
                                  }}
                                />
                              </Form.Item>

                              <Form.Item
                                label={generalTexts.lastNameLabel}
                                name={[field.name, "lastName"]}
                                fieldKey={[field.fieldKey, "lastName"]}
                                rules={[
                                  {
                                    required: true,
                                    message: validateMessages.required,
                                  },
                                ]}
                                help={""}
                                style={{
                                  display: "inline-block",
                                  width: "calc(35%)",
                                  paddingRight: "10px",
                                  marginBottom: "0px",
                                }}
                              >
                                <Input
                                  placeholder={generalTexts.lastNamePlaceholder}
                                  style={{
                                    marginBottom: "0px",
                                  }}
                                />
                              </Form.Item>

                              <Form.Item
                                label={generalTexts.birthdateLabel}
                                name={[field.name, "birthdate"]}
                                fieldKey={[field.fieldKey, "birthdate"]}
                                validateStatus={
                                  ageValue.validateStatus === "success"
                                    ? "success"
                                    : "error"
                                }
                                help={ageValue.errorMsg}
                                style={{
                                  display: "inline-block",
                                  width: "calc(15%)",
                                  marginBottom: "0px",
                                }}
                              >
                                <InputNumber
                                  min={6}
                                  max={22}
                                  value={ageValue.value}
                                  onChange={onNumberChange}
                                  style={{
                                    marginBottom: "0px",
                                    width: "calc(90%)",
                                  }}
                                />
                              </Form.Item>

                              <Form.Item
                                style={{
                                  display: "inline-block",
                                  width: "calc(15%)",
                                  marginBottom: "0px",
                                }}
                              >
                                <MinusCircleOutlined
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                  className="deleteMinus"
                                />
                              </Form.Item>
                            </Form.Item>
                          ))}

                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => {
                                add();
                              }}
                              block
                            >
                              <PlusOutlined /> Person hinzufügen
                            </Button>
                          </Form.Item>
                        </div>
                      );
                    }}
                  </Form.List>
                </Form.Item>

                <Title level={3}>{generalTexts.titleSection3}</Title>

                <Form.Item
                  label={generalTexts.nameMainContactLabel}
                  name="nameMainContact"
                  style={{ marginBottom: 0 }}
                  rules={[{ required: true, message: " " }]}
                >
                  <Form.Item
                    name="firstNameMainContact"
                    rules={[
                      { required: true, message: validateMessages.required },
                      { validator: textInputLengthValidation },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50%)",
                      paddingRight: "10px",
                    }}
                  >
                    <Input
                      placeholder={generalTexts.nameMainContactPlaceholder}
                      onChange={handleParentNameMainContactValue}
                    />
                  </Form.Item>

                  <Form.Item
                    name="lastNameMainContact"
                    rules={[
                      { required: true, message: validateMessages.required },
                      { validator: textInputLengthValidation },
                    ]}
                    style={{ display: "inline-block", width: "calc(50%)" }}
                  >
                    <Input
                      placeholder={generalTexts.lastNameMainContactPlaceholder}
                      onChange={handleParentNameMainContactValue}
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label={generalTexts.addressMainContactLabel}
                  name="addressMainContact"
                  style={{ marginBottom: 0 }}
                  rules={[{ required: true, message: " " }]}
                >
                  <Form.Item
                    name="addressMainContactStreetAndNumber"
                    rules={[
                      { required: true, message: validateMessages.required },
                      { validator: textInputLengthValidation },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(60%)",
                      paddingRight: "10px",
                    }}
                  >
                    <Input
                      placeholder={
                        generalTexts.addressMainContactStreetAndNumberPlaceholder
                      }
                      onChange={handleParentAddressMainContactValue}
                    />
                  </Form.Item>
                  <Form.Item
                    name="addressMainContactPostcode"
                    rules={[
                      { required: true, message: validateMessages.required },
                      { validator: textInputPostcodeValidation },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(20%)",
                      paddingRight: "10px",
                    }}
                  >
                    <Input
                      placeholder={
                        generalTexts.addressMainContactPostcodePlaceholder
                      }
                      onChange={handleParentAddressMainContactValue}
                    />
                  </Form.Item>
                  <Form.Item
                    name="addressMainContactPlace"
                    rules={[
                      { required: true, message: validateMessages.required },
                    ]}
                    style={{ display: "inline-block", width: "calc(20%)" }}
                  >
                    <Input
                      placeholder={
                        generalTexts.addressMainContactPlacePlaceholder
                      }
                      onChange={handleParentAddressMainContactValue}
                    />
                  </Form.Item>

                  <Form.Item
                    name="addressMainContactCountry"
                    rules={[
                      { required: true, message: validateMessages.required },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(10%)",
                      paddingRight: "10px",
                    }}
                  >
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder={
                        generalTexts.addressMainContactCountryPlaceholder
                      }
                      optionFilterProp="children"
                      onChange={onChangeCountrySelect}
                      onSearch={onSearchCountrySelect}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {countries.map((country) => (
                        <Select.Option value={country.name}>
                          {country.name}
                        </Select.Option>
                      ))}{" "}
                    </Select>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  name="phoneMainContact"
                  label={generalTexts.phoneMainContactLabel}
                  rules={[{ required: true }]}
                >
                  <Input
                    addonBefore={prefixTel}
                    placeholder={generalTexts.phoneMainContactPlaceholder}
                    style={{ width: "50%" }}
                    allowClear
                  />
                </Form.Item>

                <Form.Item
                  name="emailMainContact"
                  label={generalTexts.emailMainContactLabel}
                  rules={[{ required: true, type: "email" }]}
                >
                  <Input
                    placeholder={generalTexts.emailMainContactPlaceholder}
                  />
                </Form.Item>

                <Title level={3}>{generalTexts.titleSection4}</Title>

                <Form.Item
                  name="productionLength"
                  label={generalTexts.productionLengthLabel}
                  rules={[
                    { required: true, message: validateMessages.required },
                    { validator: textInputLengthValidationShort },
                  ]}
                >
                  <Input
                    placeholder={generalTexts.productionLengthPlaceholder}
                  />
                </Form.Item>

                <Form.Item
                  className="wrap-label"
                  name="productionTime"
                  label={generalTexts.productionTimeLabel}
                  rules={[
                    { required: true, message: validateMessages.required },
                    { validator: textInputLengthValidationShort },
                  ]}
                >
                  <Input placeholder={generalTexts.productionTimePlaceholder} />
                </Form.Item>

                <Form.Item
                  className="wrap-label"
                  name="productionShortSummary"
                  label={generalTexts.productionShortSummaryLabel}
                  rules={[
                    { required: true, message: validateMessages.required },
                    { validator: textInputLengthValidation },
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder={generalTexts.productionShortSummaryPlaceholder}
                  />
                </Form.Item>

                <Form.Item
                  className="wrap-label"
                  name="productionParticipantsDescription"
                  label={generalTexts.productionParticipantsDescriptionLabel}
                >
                  <TextArea
                    rows={4}
                    placeholder={
                      generalTexts.productionParticipantsDescriptionPlaceholder
                    }
                  />
                </Form.Item>

                <Form.Item
                  className="wrap-label"
                  label={generalTexts.hasFurtherProjectsPlannedLabel}
                  name="hasFurtherProjectsPlanned"
                >
                  <Space>
                    <Text strong>
                      {generalTexts.hasFurtherProjectsPlannedQuestion}
                    </Text>
                    <Switch
                      onChange={setHasFurtherProjects}
                      checkedChildren={generalTexts.yes}
                      unCheckedChildren={generalTexts.no}
                    />
                  </Space>
                </Form.Item>

                {hasFurtherProjects && (
                  <Form.Item
                    name="furtherProjectsDescription"
                    label={generalTexts.furtherProjectsDescriptionLabel}
                    rules={[
                      { required: true, message: validateMessages.required },
                      { validator: textInputLengthValidation },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      placeholder={
                        generalTexts.furtherProjectsDescriptionPlaceholder
                      }
                    />
                  </Form.Item>
                )}

                <Form.Item
                  label={generalTexts.hasReceivedHelpLabel}
                  name="hasReceivedHelp"
                >
                  <Space>
                    <Text strong>{generalTexts.helpReceivedQuestion}</Text>
                    <Switch
                      onChange={setHasReceivedHelp}
                      checkedChildren={generalTexts.yes}
                      unCheckedChildren={generalTexts.no}
                    />
                  </Space>
                </Form.Item>

                {hasReceivedHelp && (
                  <Form.Item
                    name="productionParticipantHelpers"
                    label={generalTexts.productionParticipantHelpersLabel}
                    rules={[
                      { required: true, message: validateMessages.required },
                      { validator: textInputLengthValidation },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      placeholder={
                        generalTexts.productionParticipantHelpersPlaceholder
                      }
                    />
                  </Form.Item>
                )}

                <Form.Item
                  label={generalTexts.hasPublicContactInformationLabel}
                  name="hasPublicContactInformation"
                  className="wrap-label"
                >
                  <Space>
                    <Text strong>
                      {generalTexts.hasPublicContactInformationQuestion}
                    </Text>
                    <Switch
                      onChange={setHasPublicContactInformation}
                      checkedChildren={generalTexts.yes}
                      unCheckedChildren={generalTexts.no}
                    />
                  </Space>
                </Form.Item>

                {hasPublicContactInformation && (
                  <>
                    <Form.Item
                      name="emailPublic"
                      label={generalTexts.emailPublicLabel}
                      rules={[{ type: "email", required: true }]}
                      className="wrap-label"
                    >
                      <Input
                        placeholder={generalTexts.emailPublicPlaceholder}
                      />
                    </Form.Item>

                    <Form.Item
                      name="websitePublic"
                      label={generalTexts.websitePublicLabel}
                      rules={[
                        { required: true, message: validateMessages.required },
                      ]}
                      className="wrap-label"
                    >
                      <Input
                        /* addonBefore="http://" */ placeholder={
                          generalTexts.websitePublicPlaceholder
                        }
                      />
                    </Form.Item>
                  </>
                )}

                <Title
                  level={4}
                  {...tailFormItemLayout2}
                  style={{ color: primaryColor, marginBottom: 10 }}
                >
                  {generalTexts.titleSection5}
                </Title>

                <Form.Item name="newLetterAgreement" valuePropName="checked">
                  <Space>
                    <Checkbox defaultChecked={checkedNewLetterAgreement}>
                      <Text>{generalTexts.newsLetterAgreement}</Text>
                    </Checkbox>
                  </Space>
                </Form.Item>

                <Title
                  level={4}
                  {...tailFormItemLayout3}
                  style={{
                    color: primaryColor,
                    marginTop: 20,
                    marginBottom: 10,
                  }}
                >
                  {generalTexts.titleSection6}
                </Title>

                <Form.Item
                  name="conditionsOfParticipation"
                  valuePropName="checked"
                  initialValue={checkedConditionsOfParticipation}
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(validateMessages.required),
                    },
                  ]}
                >
                  <Space>
                    <Checkbox defaultChecked={checkedConditionsOfParticipation}>
                      <Text>
                        {generalTexts.participationConditionsAgreementPart1 +
                          " "}
                        <b>
                          <a href={generalTexts.privacyLink}>
                            {generalTexts.participationConditionsAgreementPart2}
                          </a>
                        </b>
                        {" " +
                          generalTexts.participationConditionsAgreementPart3}{" "}
                      </Text>
                    </Checkbox>
                  </Space>
                </Form.Item>

                <Title
                  level={4}
                  {...tailFormItemLayout2}
                  style={{ color: primaryColor, marginBottom: 10 }}
                >
                  {generalTexts.titleSection7}
                </Title>

                <Form.Item 
                  name="presenceAgreement" 
                  valuePropName="checked" 
                  initialValue={checkedPresenceAgreement}
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(validateMessages.required),
                    },
                  ]}
                >
                  <Space>
                    <Checkbox defaultChecked={checkedPresenceAgreement}>
                      <Text>{generalTexts.presenceAgreement}</Text>
                    </Checkbox>
                  </Space>
                </Form.Item>





                <CustomUploadyProgress />
                {Log.d(
                  "itemsInProgress " + JSON.stringify(itemsInProgress)
                )}

                {uploadImageMetaData !== null &&
                uploadVideoMetaData !== null ? (
                  <>
                    <Title
                      level={5}
                      /* style={{ color: primaryColor, marginBottom: 15, marginTop: 30 }} */
                    >
                      {generalTexts.uploaded}
                    </Title>
                  </>
                ) : (
                  <>
                    <Title
                      level={3}
                      /* style={{ color: primaryColor, marginBottom: 15, marginTop: 30 }} */
                    >
                      {generalTexts.upload}
                    </Title>

                    <CustomUploadyBox />
                    {/* <Button onClick={() => abortAll()}>Abbrechen</Button> */}
                  </>
                )}

                <Title
                  level={5}
                  style={{
                    color: primaryColor,
                    marginBottom: 15,
                    marginTop: 30,
                  }}
                ></Title>
                {/* 
                <Title level={5} style={{ color: primaryColor, marginBottom: 15, marginTop: 30 }}>{generalTexts.verificationHeadline}</Title>

                <Form.Item>
                    <CustomCaptcha onVerifyCaptcha={onVerifyCaptcha} />
                </Form.Item> */}
                <Paragraph style={{ color: primaryColor, marginBottom: 60 }}>
                  <b>{generalTexts.infoContact1 + " "} <a href={"mailto:"+generalTexts.infoContact2} style={{ color: "red" }}>{generalTexts.infoContact2}</a>{" " + generalTexts.infoContact3 + " "} <a href={"tel:"+generalTexts.infoContactNormalizedPhone} style={{ color: "red" }}>{generalTexts.infoContact4}</a></b>
                </Paragraph>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !debugMode &&
                      (!debugMode ||
                        !form.isFieldsTouched(true) ||
                        form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length ||
                        form.isFieldsValidating) &&
                      !(
                        uploadImageMetaData !== null &&
                        uploadVideoMetaData !== null
                      )
                    }
                    //add captcha check
                  >
                    {generalTexts.send}
                  </Button>
                  <Space>
                    {!debugMode ? (
                      <></>
                    ) : (
                      <>
                        <Button
                          onClick={() => {
                            if (
                              allFormValues &&
                              uploadImageMetaData !== null &&
                              uploadVideoMetaData !== null
                            ) {
                              allFormValues[
                                "uploadImageMetaData"
                              ] = uploadImageMetaData;
                              allFormValues[
                                "uploadVideoMetaData"
                              ] = uploadVideoMetaData;
                              setAllFormValues(allFormValues);
                              /* form.getFieldInstance('productionTitle'). */
                              Log.d(JSON.stringify(allFormValues));
                              setShowPDFEditPreview(true);
                            }
                          }}
                        >
                          Debug Render PDF (Nicht vergessen vor dem Abschicken
                          1x die Teilnamebedingung Checkbox aus/ein schalten)
                        </Button>
                      </>
                    )}
                  </Space>
                </Form.Item>
              </Form>

              {/* Use it for PDF Design Updates */}
              {showPDFEditPreview && (
                <PDFKitIntegration
                  formValues={allFormValues}
                  texts={generalTexts}
                  shouldUpload={false}
                  showPreviewState={true}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default withTheme(ParticipantRegistrationForm);
